<template>
<div>
<Header></Header>
<div style="margin-top: 90px;">
  <router-view />
</div>
</div>
</template>
<script>
import Header from '@/components/header';
export default {
components:{
  Header
},
}

</script>
