<template>
  <div >
    <div class="columns">
      <div class="column is-mobile is-one-third" style="margin-left:50px;">
      <div
        class="columns"
        style="display:flex; flex-direction:column; align-items:flex-end;align-content: space-between"
      >
        <div class="column" style="margin-top:10px">
          <h5 class="title is-5">Archivo EYR:</h5>
        </div>
        <div class="column" style="margin-top:10px; display:flex;">
          <h5 class="title is-5">Archivo DG:</h5>
        </div>
      </div>
    </div>
    <div class="column">
      <div
        class="columns"
        style="display:flex; flex-direction:column; align-items:flex-start;align-content: space-between"
      >
        <div class="column">
          <b-field class="file is-info">
            <b-upload v-model="File_EYR" class="file-label">
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click Para Subir Archivo</span>
              </span>
            </b-upload>
            <span class="file-name" v-if="File_EYR">
              <button
                class="delete is-small"
                type="button"
                @click="eliminarEYR()"
              ></button>
              {{ File_EYR.name }}
            </span>
          </b-field>
        </div>
        <div class="column">
          <b-field class="file is-info">
            <b-upload
              v-model="File_DG"
              class="file-label"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click Para Subir Archivo</span>
              </span>
            </b-upload>
            <span class="file-name" v-if="File_DG">
              <button
                class="delete is-small"
                type="button"
                @click="eliminarDG()"
              ></button>
              {{ File_DG.name }}
            </span>
          </b-field>
        </div>
      </div>
    </div>
    </div>
    <div class="columns is-centered">
      <div  class="column is-half has-text-centered"  >
       <section>
        <b-progress type="is-success" :value="progress" show-value format="percent"></b-progress>
    </section>
      </div>
      </div>
    <div class="columns is-centered">
      <div  class="column is-half has-text-centered"  >
      <b-button type="is-info" icon-left="compare-horizontal" @click="subir_EYR()" :loading = "cargando">
        Comparar
      </b-button>
    </div>
    </div>
    
  </div>
</template>

<script>
import ArchivosService from "@/services/ArchivosService";
export default {
  data() {
    return {
      prueba: null,
      cargando: false,
      progress: 0,
      check_EYR: false,
      check_NOMINA: false,
      check_DG: false,
      File_EYR: null,
      File_DG: null,
      File_NOMINA: null,
      dataDG: null,
      dataNOMINA: null
    };
  },
  methods: {
    click_DG(){
      this.File_DG = null;
      this.check_DG = !this.check_DG;
    },
    click_NOMINA(){
      this.File_NOMINA = null;
      this.check_NOMINA = !this.check_NOMINA;
    },
    eliminarEYR() {
      this.File_EYR = null;
    },
    eliminarDG() {
      this.File_DG = null;
    },
    eliminarNomina() {
      this.File_NOMINA = null;
    },
    async subir_EYR(){
      this.cargando = true;
      const respuestaEYR = await ArchivosService.subirEYR(this.File_EYR);

      if(respuestaEYR){
        this.progress += 10;
      if(this.File_DG != null){
        const respuestaDG = await ArchivosService.subirDG(this.File_DG);
        console.log(respuestaDG);
        /*if(respuestaDG){
          this.progress += 10;
          this.dataDG = respuestaDG;
          const processPV= await ArchivosService.processPV(respuestaDG, respuestaEYR);
          if(processPV){
            this.progress += 10;
            const processPC = await ArchivosService.processPC(respuestaDG, respuestaEYR);
            console.log(processPC);
            this.cargando= false;
            if(processPC){
              this.progress += 10;
               const processPE = await ArchivosService.processPE(respuestaDG, respuestaEYR);
               console.log(processPE);
               this.cargando = false;
            }
            
          }

        }*/
        if(this.File_NOMINA != null){
        const respuestaNOMINA = await ArchivosService.subirNomina(this.File_NOMINA);
        if(respuestaNOMINA){
          this.progress += 10;
          this.dataNOMINA = respuestaNOMINA;
        }
      }
      
      }
      
      }
      //this.prueba = {DG:this.dataDG, NOMINA:this.dataNOMINA, respuestaEYR};
      
      //this.cargando = false;
      }
  },
};
</script>
