import Inicio from '@/components/inicio';
import Ventas from '@/components/ventas';
import Comision from '@/components/comision';
import header from '@/components/header';
import bancos from '@/components/bancos';
import pagos from '@/components/pagos';
import sat from '@/components/sat';

const routes = [
    {
        path: '/sat',
        name: 'sat',
        component: sat
    },
    {
        path: '/banco',
        name: 'banco',
        component: bancos
    },
    { path: '/',
    name: 'inicio',
    component:     Inicio
 },
 { 
    path: '/header',
    name: 'header',
    component:     header
 },
{   path: '/ventas',
    name: 'ventas',
    component: Ventas 
},
{   path: '/pagos',
    name: 'pagos',
    component: pagos 
},
    {
        path: '/comision',
        name: 'comision',
        component: Comision },
];

export default routes;