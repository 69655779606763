<template>
  <figure class="image is-3by1">
  <img src="@/assets/CONTRA.png">
  <div> <h2>hola</h2></div>
</figure>


</template>

<script>
export default {
}

</script>
