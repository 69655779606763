<template>
  <div class="columns">
    <div class="column has-text-centered">
      <h2 class="is-size-2 has-text-centered">
        <strong>Subir Archivo Reporte Comision</strong>
      </h2>
      <div class="column is-flex is-justify-content-center" v-if="errorMsg">
        <b-message type="is-danger" size="is-small">
          {{ errorMsg }}
        </b-message>
      </div>
      <div class="columns is-mobile is-centered" v-if="form == null && download == false">
        <div class="column is-two-fifths">
          <div class="box">
            <b-field>
              <b-upload :loading="cargando" v-model="archivo" drag-drop required
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Arrastra aquí el archivo o haz clic para buscarlo</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags" style="
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
              ">
              <span v-if="archivo" class="notification is-large is-info">
                <strong>Archivo:</strong> {{ archivo.name }}<br />
                <strong>Tipo de archivo:</strong> {{ archivo.type }}<br />
                <strong>Tamaño:</strong> {{ conversionSize(archivo.size) }}
                <button class="delete is-small" type="button" @click="eliminarArchivo()"></button>
              </span>
            </div>
            <b-field>
              <b-button v-if="form == null" type="is-success" @click="subirArchivos()" :disabled="archivo == null">
                Subir
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-centered" v-if="form != null && download == false">
        <div class="column is-half">
          <div class="box">
            <div style="display: flex; flex-direction: row-reverse">
              <span>
                <b-button style="border: none; background-color: #fff" class="is-medium" rounded icon-left="refresh"
                  type="button" @click="refresh()"></b-button>
              </span>
            </div>
            <b-field label="Año">
              <b-numberinput type="is-success" min="2022" v-model="Anio" placeholder="Añade la año del reporte "
                controls-rounded></b-numberinput>
            </b-field>
            <b-field label="Mes">
              <b-numberinput type="is-success" min="1" max="12" placeholder="Añade el mes del reporte " v-model="Mes"
                controls-rounded></b-numberinput>
            </b-field>
            <b-field label="Semana">
              <b-numberinput type="is-success" placeholder="Añade la semana del reporte" min="1" max="52"
                v-model="number" controls-rounded></b-numberinput>
            </b-field>
            <b-field label="Inicio Periodo">
              <b-numberinput type="is-success" placeholder="Añade el inicio del Periodo" min="1" max="31"
                v-model="inicio" controls-rounded></b-numberinput>
            </b-field>
            <b-field label="Final Periodo">
              <b-numberinput type="is-success" placeholder="Añade el Final del Periodo" min="1" max="31"
                v-model="termino" controls-rounded></b-numberinput>
            </b-field>
            <b-field>
              <b-button type="is-info" :loading="cargando" @click="procesarData()" :disabled="Anio == null ||
        Mes == null ||
        termino == null ||
        inicio == null ||
        number == null
        ">
                Procesar
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-centered" v-if="download != false">
        <div class="column is-half">
          <div class="box">
            <div style="display: flex; flex-direction: row-reverse">
              <span>
                <b-button style="border: none; background-color: #fff" class="is-medium" rounded icon-left="refresh"
                  type="button" @click="refresh()"></b-button>
              </span>
            </div>
            <h2 class="is-size-4 has-text-centered" style="margin-bottom: 20px; margin-top: 20px">
              <strong>Da click en el boton de abajo para descargar el archivo</strong>
            </h2>
            <b-field>
              <b-button type="is-success" :loading="cargando" @click="descargarPDF()">
                Descargar PDF
              </b-button>
            </b-field>
            <b-field>
              <b-button type="is-info" :loading="cargando" @click="descargarCSV()">
                Descargar CSV
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchivosService from "../services/ArchivosService";
import Constantes from "@/Constantes";

export default {
  data() {
    return {
      archivo: null,
      archivos: [],
      form: null,
      cargando: false,
      Anio: null,
      Mes: null,
      termino: null,
      inicio: null,
      number: null,
      dir: null,
      download: false,
      reload: false,
      labelPosition: "on-border",
      agencias: [],
      uuid: null,
      ubicacion: "",
      errorMsg: null
    };
  },
  methods: {
    async procesarData() {
      this.cargando = true;
      console.log(this.agencias)
      const respuesta = await ArchivosService.procesarComision(
        this.Mes,
        this.Anio,
        this.number,
        this.inicio,
        this.termino,
        this.agencias,
        this.ubicacion,
        this.number
      );
      if (respuesta) {
        this.dir = respuesta[1];
        this.cargando = false;
        this.download = true;
        this.message("El archivo esta listo para descargarse", "is-success");
      }
    },
    eliminarArchivo() {
      this.archivo = null;
    },
    async subirArchivos() {
      this.errorMsg = null
      if (this.archivo == null) {
        return;
      }
      try {
        this.cargando = true;
        const respuesta = await ArchivosService.subirArchivosComision(this.archivo);
        if (respuesta != false) {
          this.ubicacion = respuesta[0];
          this.uuid = respuesta[1];
          this.agencias = respuesta[2];
          this.message("Archivo subido correctamente", "is-success");
          this.form = true;
        } else if (respuesta == false) {
          this.errorMsg = "Error Ventas al subir archivos, contactar al departamento de IT"
          this.cargando = false
        } else {
          this.errorMsg = "Error Ventas al subir archivos, contactar al departamento de IT"
        }
      } catch (e) {
        this.message(`Error subiendo archivos: ${e}`, "is-danger");
      } finally {
        this.archivo = null;
        this.cargando = false;
      }
    },
    async descargarCSV() {
      const tipo = "ComisionReportesS0";
      const respuesta = await ArchivosService.obtenerCSV(
        this.dir,
        this.ubicacion,
        this.Anio,
        this.number,
        tipo,
        this.agencias
      );
      if (respuesta) {
        const url =
          Constantes.URL_SERVIDOR +
          "/descargar_archivo.php?ruta=" +
          respuesta[0] +
          "&name=" +
          respuesta[1];
        window.open(url);

        setTimeout(() => {
          this.reload = true;
          this.message(
            "Tu archivo se a descargado correctamente!",
            "is-success"
          );
        }, 2000);
      }
    },
    async descargarPDF() {
      const respuesta = await ArchivosService.descargar(
        this.dir,
        this.ubicacion,
        this.Anio,
        this.number
      );

      if (respuesta) {
        const tipo = "ComisionReportesS0" + this.number + this.Anio;
        const url =
          Constantes.URL_SERVIDOR +
          "/descargar.php?name=" +
          respuesta +
          "&tipo=" +
          tipo;
        window.open(url);

        setTimeout(() => {
          this.reload = true;
          this.message(
            "Tu archivo se a descargado correctamente!",
            "is-success"
          );
        }, 2000);
      }
    },
    conversionSize(bytes) {
      const kb = 1024;
      const mb = kb * 1024;
      const gb = mb * 1024;

      if (bytes < kb) {
        return bytes + 'bytes'
      } else if (bytes < mb) {
        return (bytes / kb).toFixed(2) + ' KB'
      } else if (bytes < gb) {
        return (bytes / mb).toFixed(2) + ' MB'
      } else {
        return (bytes / gb).toFixed(2) + ' GB'
      }
    },
    async refresh() {
      if (this.uuid) {
        const respuesta = await ArchivosService.LimpiarData(this.uuid);
        console.log(respuesta)
        if (respuesta) {
          if (this.dir) {
            const eliminiarPdf = await ArchivosService.LimpiarPDF(this.dir);
            if (eliminiarPdf) {
              const eliminarZip = await ArchivosService.eliminarZip(respuesta);
              if (eliminarZip) {
                location.reload();
              }
            }
          } else {
            location.reload();
          }
        }
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>
