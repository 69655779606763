<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a @click="navegarHacia('inicio')">
          <img
            src="@/assets/logo_contrav.png"
            id="icon"
            style="height: 70px"
            class="navbar-item"
            alt="User Icon"
          />
        </a>
        <a
          role="button"
          @click="menuEstaActivo = !menuEstaActivo"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        class="navbar-menu"
        :class="{ 'is-active': menuEstaActivo }"
      >
        <div class="navbar-start">
          <a class="navbar-item" @click="navegarHacia('ventas')">
            <b-icon class="mr-1" icon="cash"></b-icon>&nbsp; Reporte Ventas
          </a>

          <a class="navbar-item" @click="navegarHacia('comision')">
            <b-icon class="mr-1" icon="cash-multiple"></b-icon>&nbsp; Reporte
            Comision
          </a>
          <a class="navbar-item" @click="navegarHacia('banco')">
            <b-icon class="mr-1" icon="bank-outline"></b-icon>&nbsp;
            Conciliacion bancos
          </a>
          <a class="navbar-item" @click="navegarHacia('pagos')">
            <b-icon class="mr-1" icon="credit-card-outline"></b-icon>&nbsp;
            Conciliacion Ingresos
          </a>
          <!-- <a class="navbar-item" @click="navegarHacia('sat')">
            <b-icon class="mr-1" icon="code-string"></b-icon>&nbsp;
            SAT
          </a> -->
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons"></div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import ArchivosService from "@/services/ArchivosService";
export default {
  name: "app",

  data: () => ({
    deberiaMostrarMenu: false,
    menuEstaActivo: false,
  }),
  mounted() {
    this.limpiar();
  },
  methods: {
    async limpiar(){
      const respuesta = await ArchivosService.cleanAll();
      if(respuesta){
        const eliminarZip = await ArchivosService.eliminarZip(respuesta);
        console.log(eliminarZip);
      }
    },
    mostrarMenu() {
      this.deberiaMostrarMenu = true;
    },
    ocultarMenu() {
      this.deberiaMostrarMenu = false;
    },
    navegarHacia(nombreRuta) {
      this.$router.push({
        name: nombreRuta,
      });
      this.menuEstaActivo = false;
    },
  },
};
</script>
