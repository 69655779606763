import HttpService from "../services/HttpService";

const ArchivosService = {
  async subirDG(DG){
    const formData = new FormData();

    formData.append("DG", DG);

    return await HttpService.formdata("/subirDG.php", formData);
  },
  async subirNomina(NOMINA){
    const formData = new FormData();

    formData.append("NOMINA", NOMINA);

    return await HttpService.formdata("/subirNOMINA.php", formData);
  },
  async subirEYR(EYR){
    const formData = new FormData();

    formData.append("EYR", EYR);

    return await HttpService.formdata("/subirEYR.php", formData);
  },
  async subirArchivos(archivos) {
    const formData = new FormData();

    formData.append("archivo", archivos);

    return await HttpService.formdata("/subir_archivos.php", formData);
  },
  async subirArchivosConciliacionBanco(bancos,icaav) {
    const form = new FormData();

    form.append("bancos", icaav);
    form.append("icaav", bancos);

    return await HttpService.formdata("/subirConciliacionBancos.php", form);
  },
  async subirArchivosConciliacionIngresos(icaav,ventas) {
    const form = new FormData();

    form.append("icaav", icaav);
    form.append("ventas", ventas);

    return await HttpService.formdata("/subirConciliacionIngresos.php", form);
  },
  async subirArchivosComision(archivos) {
    const formData = new FormData();

    formData.append("archivo", archivos);

    return await HttpService.formdata("/subir_archivosComision.php", formData);
  },
  async generateZip(dir){
    return await HttpService.post("/generateZip.php", {
      dir: dir
    });
  },
  async LimpiarData(uuid){
    return await HttpService.post("/LimpiarData.php", {
      uuid: uuid
    });
  },
  async LimpiarPDF(dir){
    return await HttpService.post("/LimpiarPDF.php", {
      dir: dir
    });
  },
  async cleanAll(){
    return await HttpService.post("/Limpiar.php");
  },
  async eliminarZip(uuid){
    return await HttpService.post("/eliminarZip.php", uuid);
  },
  async descargar(dir,dir_save,anio,semana, tipo){
    return await HttpService.post("/generarZip.php", {
      dir: dir,
      dir_save:dir_save,
      anio: anio,
      semana: semana,
      tipo: tipo
    });
  },
  async descargarBancos(dir){
    return await HttpService.post("/generarZipBancos.php", {
      dir: dir
    });
  },
  async obtenerCSV(dir,dir_save,anio,semana, tipo,agencias){
    return await HttpService.post("/comisionCSV.php", {
      dir: dir,
      dir_save:dir_save,
      anio: anio,
      semana: semana,
      tipo: tipo,
      agencias: agencias
    });
  },
  async obtenerVentaCSV(dir,dir_save,anio,semana, tipo,agencias){
    return await HttpService.post("/ventasCSV.php", {
      dir: dir,
      dir_save:dir_save,
      anio: anio,
      semana: semana,
      tipo: tipo,
      agencias: agencias
    });
  },
  async procesarVentas(mes, anio, number, inicio, termino,agencias, ubicacion, semana) {
    return await HttpService.post("/procesar_ventas.php", {
      mes: mes,
      anio: anio,
      number: number,
      inicio: inicio,
      termino: termino,
      agencias: agencias,
      ubicacion: ubicacion,
      semana: semana
    });
  },

async procesarComision(mes, anio, number, inicio, termino,agencias, ubicacion, semana) {
  return await HttpService.post("/procesar_comision.php", {
    mes: mes,
    anio: anio,
    number: number,
    inicio: inicio,
    termino: termino,
    agencias: agencias,
    ubicacion: ubicacion,
    semana: semana
  });
},
async compararArchivos(data){
  return await HttpService.post("/matchBancos.php", data);
},
async compararArchivosIngresos(data){
  return await HttpService.post("/matchIngresos.php", data);
},
async generarArchivoIngresos(data){
  return await HttpService.post("/generarArchivoIngresos.php", data);
},
async generarArchivos(data){
  return await HttpService.post("/generarArchivosBanco.php", data);
},
async processPV(dataDG, dataEyR){
  return await HttpService.post("/procesarPV.php", {
    dataDG : dataDG,
    dataEyR : dataEyR 
  });
},
async processPC(dataDG, dataEyR){
  return await HttpService.post("/procesarPC.php", {
    dataDG : dataDG,
    dataEyR : dataEyR 
  });
},
async processPE(dataDG, dataEyR){
  return await HttpService.post("/procesarPE.php", {
    dataDG : dataDG,
    dataEyR : dataEyR 
  });
}
};
export default ArchivosService;
