<template>
  <div>
    <div style="display: flex; justify-content: center;" v-if="errorMsg">
      <b-message type="is-danger" size="is-small">
            {{errorMsg}}
        </b-message>
    </div>
    <div class="columns">
    <b-modal v-model="modal" :can-cancel="false">
      <div class="columns is-centered">
        <div class="column is-5">
          <div class="modal-card-body">
            <strong>Aviso:</strong>
            Asegurate de que sean los documentos correctos antes de continuar.
          </div>
          <div class="modal-card-foot" style="justify-content: center">
            <b-field>
              <b-button type=" is-small" @click="modal = false">
                Cancelar
              </b-button>
              <b-button
                type="is-info is-small"
                @click="process(archivo_icaav, archivo_banco)"
              >
                Continuar
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="column has-text-centered">
      <h2 class="is-size-4 has-text-centered">
        <strong>Subir Archivo Bancos</strong>
      </h2>
      <div class="columns is-mobile is-centered">
        <div class="column is-three-fifths">
          <div class="box">
            <b-field>
              <b-upload v-model="archivo_banco" drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Arrastra aquí el archivo o haz clic para buscarlo</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div
              class="tags"
              style="
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
              "
            >
              <span
                v-if="archivo_banco && archivo_banco.type != 'application/vnd.ms-excel' && archivo_banco && archivo_banco.type != 'text/csv' "
                class="tag is-medium is-danger"
              >
                ¡Archivo no soportado!
                <button
                  class="delete is-small"
                  type="button"
                  @click="eliminarArchivoBanco()"
                ></button>
              </span>
              <span
                v-if="archivo_banco && archivo_banco.type == 'application/vnd.ms-excel' ||archivo_banco && archivo_banco.type == 'text/csv' "
                class="notification is-large is-danger is-light"
                style="font-size: 10pt; width: 100%"
              >
                <strong>Nombre del Archivo: </strong> <br />
                <strong>{{ archivo_banco.name }}</strong>
                <button
                  class="delete is-small"
                  type="button"
                  @click="eliminarArchivoBanco()"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="column is-1 has-text-centered"
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      "
    >
      <b-field>
        <strong v-if="cargando"> {{ mensaje }} </strong>
        <b-button
          type="is-info is-large"
          :loading="cargando"
          icon-left="compare-horizontal"
          @click="match()"
          rounded
        >
        </b-button>
      </b-field>
    </div>
    <div class="column has-text-centered">
      <h2 class="is-size-4 has-text-centered">
        <strong>Subir Archivo ICAAV</strong>
      </h2>
      <div class="columns is-mobile is-centered">
        <div class="column is-three-fifths">
          <div class="box">
            <b-field>
              <b-upload v-model="archivo_icaav" drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Arrastra aquí el archivo o haz clic para buscarlo</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div
              class="tags"
              style="
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
              "
            >
              <span
                v-if="archivo_icaav && archivo_icaav.type != 'application/vnd.ms-excel' && archivo_icaav && archivo_icaav.type != 'text/csv' "
                class="tag is-medium is-danger"
              >
                ¡Archivo no soportado!
                <button
                  class="delete is-small"
                  type="button"
                  @click="eliminarArchivoIcaav()"
                ></button>
              </span>
              <span
                v-if="archivo_icaav && archivo_icaav.type == 'application/vnd.ms-excel' ||archivo_icaav && archivo_icaav.type == 'text/csv' "
                class="notification is-large is-success is-light"
                style="font-size: 10pt; width: 100%"
              >
                <strong>Nombre del Archivo:</strong> {{ archivo_icaav.name
                }}<br />
                <button
                  class="delete is-small"
                  type="button"
                  @click="eliminarArchivoIcaav()"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>
<script>
import ArchivosService from "../services/ArchivosService"
import Constantes from "../Constantes"
export default {
  data() {
    return {
      cargando: false,
      archivo_banco: null,
      archivo_icaav: null,
      modal: false,
      mensaje: "Cargando",
      errorMsg: null
    };
  },
  methods: {
    eliminarArchivoBanco() {
      this.archivo_banco = null;
    },
    eliminarArchivoIcaav() {
      this.archivo_icaav = null;
    },
    match() {
      if (this.archivo_icaav == null || this.archivo_banco == null) {
        this.message(
          "No se encontraron documentos, asegura de que los campos esten llenos.",
          "is-danger"
        );
      } else {
        this.modal = true;
      }
    },
    async process(icaav, bancos) {
      this.errorMsg = null
      var hoy = new Date(); 
      this.cargando = true;
      this.modal = false;
      const respuesta = await ArchivosService.subirArchivosConciliacionBanco(
        icaav,
        bancos
      );
      if (respuesta) {
        if(respuesta[0].errorInfo){
          this.errorMsg = "Error ICAAV-" + respuesta[0].errorInfo[2] + ": " + respuesta[0].errorInfo[3]
          this.cargando = false
          return
        }else if(respuesta[1].errorInfo){
          this.errorMsg = "Error Banco-" + respuesta[1].errorInfo[2] + ": " + respuesta[1].errorInfo[3]
          this.cargando = false
          return 
        }
        this.mensaje = "Comparando";
        const comparar = await ArchivosService.compararArchivos(respuesta);
        this.cargando = false;
        if (comparar) {
          this.mensaje = "Generando";
          const generar = await ArchivosService.generarArchivos(comparar);
          if (generar) {
            this.mensaje = "Comprimiendo";
            const zip = await ArchivosService.descargarBancos(generar);
            if (zip) {
              const tipo = "Contravel" + hoy.getFullYear() + (hoy.getMonth() + 1) + hoy.getDate() ;
              const url = Constantes.URL_SERVIDOR +
                "/descargar.php?name=" +
                zip +
                "&tipo=" +
                tipo;
              window.open(url);
              this.message("Archivo Descargado Exitosamente.", "is-success");
              location.reload();
            }
            this.cargando = false;
          }
        }
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>
<style>
.modal-content,
.modal-card {
  overflow: hidden;
}
.modal-card-body {
  border-radius: 10px 10px 0px 0px;
}
</style>
